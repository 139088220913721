.login-container {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
}

form {
    padding-top: 10px;
    font-size: 14px;
    margin-top: 30px;
}

.card-title {
    font-weight: 300;
}

.login-form {
    width: 330px;
    margin: 20px;
}

.sign-up {
    text-align: center;
    padding: 20px 0 0;
}

.alert {
    margin-bottom: -30px;
    font-size: 13px;
    margin-top: 20px;
}
.editor-sidebar {
    background-color: #e9ecef;
    padding: 12px;
    border-radius: 6px;
    margin-bottom: 18px;
    .row {
        margin-right: 0;
        margin-left: 0;
    }
}

.cross-btn {
    background-color: #ff0000;
    border-radius: 100%;
    padding: 12px;
    svg {
        width: 18px;
        height: 12;
    }
}

.delete-button {
    font-family: "Open Sans";
    font-size: 22px;
    font-weight: 400;
    display: inline-block;
    color: #fff;
    border-radius: 0.25em;
    text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.4);
    border: none;
}

.touch {
    transition: ease-in-out 0.2s;
    line-height: 20px;
    width: 40px;
    padding: 0px;
    text-align: center;
}

.delete {
    background: #c0496f;
}
.delete:hover{
    background-color:  #882746;
}
