.booking-card {
    background-color: #ffffff;
    padding-left: 0px;
    padding-top: 30px;
    padding-bottom: 30px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;

    .border-right-dark {
        border-right: 1px solid rgb(107, 107, 107);
    }
}
.text-underline {
    text-decoration: underline;
}
