@media (max-width: 600px) {
    aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-belowO {
        position: fixed !important;
        z-index: 5 !important;
    }
}

.menu-backup {
    height: 100vh;
    flex: 0 0 200px;
    max-width: 200px;
    min-width: 200px;
    position: fixed;
    width: 200px;
}
.trigger {
    padding: 0 24px;
    font-size: 22px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #075e54;
}

.logo {
    font-size: 1.25rem;
    text-align: center;
    color: #fff;
    padding: 12px 0;
}
.site-layout .site-layout-background {
    background: #fff;
}
